<template>
  <article class="pages-blog-listing-block button-circle-1-trigger" ref="block">
    <nuxt-link
      :to="
        localePath({
          name: 'blog-slug',
          params: { slug: props.item?.slug },
        })
      "
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
    >
      <div class="pages-blog-listing-block__image">
        <WidgetPicture
          class="is-cover"
          :image="{ custom: props.item?.image?.responsiveImage }"
          :lazyLoad="true"
          :alt="
            props.item?.image?.responsiveImage?.alt
              ? props.item?.image?.responsiveImage?.alt
              : props.item?.title
          "
        />
        <div class="pages-blog-listing-block__blue-retinatiion" />
        <div
          v-if="!touch"
          :class="{ '--is-hover': isHover }"
          class="pages-blog-listing-blog__hover-wrap"
        >
          <button :aria-label="$t('cta.readBlog')" class="button-round-1">
            <span>{{ $t('cta.readBlog') }}</span>
          </button>
        </div>
      </div>
      <div class="pages-blog-listing-block__header">
        <div
          v-if="props.item?.tag && showTags"
          class="pages-blog-listing-block__tag_wrap"
        >
          <ul>
            <li v-for="tag in props.item.tag" :key="tag.slug">
              <span class="text-xs-1">{{ tag.title }}</span>
            </li>
          </ul>
        </div>
        <div class="pages-blog-listing-block__title-wrap">
          <h2 class="pages-blog-listing-block__title text-l-1">
            {{ props.item?.title }}
          </h2>
        </div>
      </div>
    </nuxt-link>
  </article>
</template>

<script>
export default {
  name: 'PagesBlogListingBlock',
}
</script>

<script setup>
import { gsap } from 'gsap'

const props = defineProps({
  item: Object,
  showTags: {
    type: Boolean,
    default: true,
  },
})

const block = ref(null)

const isHover = ref(false)
const touch = ref(false)

const duration = 0.6
const durationFast = 0.4
const ease = 'power2.inOut'

onMounted(() => {
  touch.value = 'ontouchstart' in window || !!navigator.maxTouchPoints /// !! trasformano 0/1 in Bool
})

const onMouseEnter = () => {
  if (touch.value) return false

  isHover.value = true
}

const onMouseLeave = () => {
  if (touch.value) return false

  isHover.value = false
}
</script>

<style lang="scss">
@import './style.scss';
</style>
